import * as React from 'react';
import { PostRow, PostCol, LoadMoreButton } from './image-card-list.style';
import { useState } from 'react';
import Masonry from 'react-masonry-component';
import ImageCard from '../image-card/image-card';
import Button from '../button/button';

interface ImageCardListProps {
  posts: any[];
}

const ImageCardList: React.FunctionComponent<ImageCardListProps> = ({ posts }) => {
  const [state, setState] = useState({
    visibile: 4,
  });

  const [load, setload] = useState({
    loading: false,
  });

  const fetchMoreData = () => {
    setload({ loading: true });

    setTimeout(function () {
      setState((prev) => {
        return { visibile: prev.visibile + 8 };
      });
      setload({ loading: false });
    }, 1000);
  };

  return (
    <PostRow>
      <Masonry className="showcase" disableImagesLoaded={true}>
        {posts.slice(0, state.visibile).map(({ node }: any) => {
          const title = node.frontmatter.title || node.slug;
          return (
            <PostCol key={node.slug}>
              <ImageCard
                title={title}
                image={
                  node.frontmatter.image == null
                    ? null
                    : node.frontmatter.image.childImageSharp.fluid
                }
                imageType="fluid"
                url={node.frontmatter.slug}
              ></ImageCard>
            </PostCol>
          );
        })}
      </Masonry>

      {state.visibile < posts.length ? (
        <LoadMoreButton>
          <Button
            title="Load more"
            type="submit"
            onClick={fetchMoreData}
            isLoading={load.loading == true ? true : false}
            loader="Loading.."
          />
        </LoadMoreButton>
      ) : (
        ''
      )}
    </PostRow>
  );
};

export default ImageCardList;
