import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Link } from 'gatsby';

export const MasonryCardWrapper = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: -2px 9px 16px 0px rgb(164 130 185 / 19%);
  &:hover {
    .post_preview {
      a {
        transform: scale(1.05);
      }
    }
  }
`;

export const PostPreview = styled.div`
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  img {
    border-radius: 3px;
  }
  a {
    display: block;
    transition: 0.25s ease-in-out;
  }
`;

export const PostDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReadMore = styled(Link)`
  width: 100%;
  font-family: ${themeGet('fontFamily.0', "sans-serif")};
  font-size: 17px;
  font-weight: 700;
  color: #ca006a;
  background-color: #f8f5f9;
  text-transform: uppercase;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.15s ease-in-out;
  @media (max-width: 990px) {
    font-size: 14px;
  }
  text-decoration: none;
`;


export const PostTitle = styled.h2`
  font-family: ${themeGet('fontFamily.0', "sans-serif")};
  font-size: 27px;
  font-weight: 700;
  color: #1d1d1d;
  line-height: 1.33;
  letter-spacing: 0px;
  text-align: center;
  a {
    color: #1d1d1d;
  }
  padding: 20px 60px 20px;

  @media (max-width: 1200px) {
    padding: 30px 40px;
  }
  @media (max-width: 990px) {
    padding: 30px;
    font-size: 24px;
  }
  @media (max-width: 575px) {
    padding: 20px 30px;
  }
`;

export const PostExcerpt = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${themeGet('colors.textLightColor', '#595959')};
  line-height: 2;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 990px) {
    font-size: 15px;
  }
`;
