import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const PostRow = styled.div`
  margin: 0 -15px;
  @media (max-width: 990px) {
    margin: 0 -10px;
  }
`;

export const PostCol = styled.div`
  margin-bottom: 30px;
  width: 50%;
  float: left;
  padding: 0 15px;
  @media (max-width: 990px) {
    padding: 0 10px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    width: 50%;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
`;

export const LoadMoreButton = styled.div`
  text-align: center;
  margin-top: 50px;

  .button {
    text-transform: uppercase;
    font-weight: 700;

    &:hover {
      background-color: ${themeGet('primary', '#D10068')};
    }
  }
  @media (max-width: 990px) {
    margin-top: 50px;
  }
  @media (max-width: 575px) {
    margin-top: 30px;
  }
`;
