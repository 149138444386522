import * as React from 'react';
import { PostRow, PostCol, LoadMoreButton } from './post-card-list.style';
import { useState } from 'react';
import Masonry from 'react-masonry-component';
import MasonryCard from '../../components/masonry-card/masonry-card';
import Button from '../../components/button/button';

interface PostCardListProps {
  posts: any[];
}

const PostCardList: React.FunctionComponent<PostCardListProps> = ({ posts }) => {
  const [state, setState] = useState({
    visibile: 4
  });

  const [load, setload] = useState({
    loading: false
  });

  const fetchMoreData = () => {
    setload({ loading: true });

    setTimeout(function() {
      setState(prev => {
        return { visibile: prev.visibile + 8 };
      });
      setload({ loading: false });
    }, 1000);
  };

  return (
    <PostRow>
      <Masonry className="showcase" disableImagesLoaded={true}>
        {posts.slice(0, state.visibile).map(({ node }: any) => {
          const title = node.frontmatter.title || node.slug;
          return (
            <PostCol key={node.slug}>
              <MasonryCard
                title={title}
                excerpt={node.excerpt}
                image={
                  node.frontmatter.image == null
                    ? null
                    : node.frontmatter.image.childImageSharp.fluid
                }
                imageType="fluid"
                url={node.frontmatter.slug}
              />
            </PostCol>
          );
        })}
      </Masonry>
      <LoadMoreButton>
        {state.visibile < posts.length ? (
          <Button
            title="Load more"
            type="submit"
            onClick={fetchMoreData}
            isLoading={load.loading == true ? true : false}
            loader="Loading.."
          />
        ) : ''}
      </LoadMoreButton>
    </PostRow>
  );
};

export default PostCardList;
